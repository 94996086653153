import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Template from "@/views/Template";
import ProductPresentation from "@/components/ProductPresentation";
import ProductAdvantages from "@/components/ProductAdvantages";
import AboutProduct from "@/components/AboutProduct";
import Typography from "@/components/Typography";
import InvestTime from "@/containers/InvestTime";
import PlansBox from "@/components/PlansBox";
import Button from "@/components/Button/Button";
export const _frontmatter = {
  "title": "GCAP/Escrituração de Livro Caixa",
  "path": "/gcap",
  "date": "2019-01-29T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Template;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ProductPresentation contactLink="https://wa.me/5581994019577" knowMoreLink="https://www.instagram.com/leancontabilidade" description="Quando você vende algum bem precisa analisar se é necessário pagar imposto de renda. Porém, sempre irá precisar entregar a declaração GCAP para a Receita Federal" title={() => <>
      Sempre que você vender algum bem (imóvel, participação societária,
      criptoativos) é necessário entregar a{" "}
      <Typography as="span" weight={700} variant="h3" mdxType="Typography">
        Declaração GCAP
      </Typography>
      .
    </>} mdxType="ProductPresentation" />
    <InvestTime mdxType="InvestTime" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      